
import React, {Fragment, useState} from 'react'
import GrizHubLink from "./grizhublink"

const BASE_URL = process.env.MIX_BASE_URL

function Location(props) {
    const content = props.name
    const onlineLink = () => {
        if ( props.onlineLocation ) return (
            <p><a href={props.onlineLocation}>{props.onlineLocation}</a></p>
        )
    }
    return (
        <div>
            <div>{props.name}</div>
            {onlineLink()}
        </div>
    )
}

function EventDetail(props) {
    return (
        <Fragment>
            <div className={'row pb-1'}>
                <div className={'col'}>
                    <h5>Description</h5>
                </div>
            </div>
            <div className={'row'} >
                <div className={'col'}>
                    <div dangerouslySetInnerHTML={{__html: props.description}}/>
                </div>
            </div>
            <div className={'row pb-1'}>
                <div className={'col-3'}>
                    <h5>Location</h5>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <Location {...props.address} />
                </div>
            </div>

            <div className={'row'}>
                <div className={'col'}></div>
                <div className={'col-auto ml-auto'}>
                    <GrizHubLink {...props} />
                </div>
            </div>
        </Fragment>
    )

}

export default EventDetail;
