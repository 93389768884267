import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import Loading from './loading'
import dayjs from 'dayjs'
import ShowMoreButton from './showmorebutton'
import DayEventList from './dayeventlist'

const API_BASE_URL = process.env.MIX_BASE_URL


function Events(props) {
    const [data, setData] = useState({ totalItems: 0, events: []})
    const [sliceCount,setSliceCount] = useState(5)
    const [isLoading, setIsLoading] = useState(false);
    useEffect( () => {
        const fetchData = async () => {
            setIsLoading(true)
            const result = await axios(
                API_BASE_URL+'api/events' +
    		    (props.organizationid ? ('?organizationIds='+props.organizationid) : '')
            )
            setData(result.data)
            setIsLoading(false)
        }
        fetchData()
    }, [])

    const clickHandler = () => {
        setSliceCount(sliceCount + 5)
    }

    let daysEvents = []
    let currentEventDate = undefined
    let out = []
    let loopLength = sliceCount;
    if(data.events.length < sliceCount) {
        loopLength = data.events.length
    }

    for(let c=0; c<loopLength; c++)
    {
        let eventDate = dayjs(data.events[c].startsOn)
        if(eventDate.isAfter(currentEventDate,'day') && c !== 0)
        {
            out.push(<DayEventList key={currentEventDate.format('YYMMDD')} events={daysEvents} />)
            daysEvents = []
        }
        daysEvents.push(data.events[c])
        currentEventDate = eventDate
    }
    if(daysEvents.length > 0) {
        out.push(<DayEventList key={currentEventDate.format('YYMMDD')} events={daysEvents} />)
    }

    return (
        <div className="container-fluid events">
            <Loading isLoading={isLoading} />
            { out }
            <ShowMoreButton onClick={clickHandler} count={sliceCount} length={data.events.length}  />
        </div>
    )

}

export default Events
