import React, {Fragment} from 'react'
import dayjs from 'dayjs'
import EventList from './eventlist'

const DayEventList = (props) => {
    const startDateTime = dayjs(props.events[0].startsOn)
    return <Fragment>
        <h3>{startDateTime.format('dddd, MMMM D')}</h3>
        <EventList events={props.events} />
    </Fragment>
}

export default DayEventList
