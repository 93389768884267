import React, {Fragment, useState} from 'react'
import useCollapse from 'react-collapsed';
import EventTimeFrame from './eventtimeframe'
import EventDetail from './eventdetail'
import EventOnline from './eventonline.jsx'


function Event(props) {
    const [isExpanded, setExpanded] = useState(false);
    const {getCollapseProps, getToggleProps} = useCollapse({ isExpanded});

    const eventProps = {
        className: 'event mb-3',
    }

    const toggleProps = getToggleProps({
        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        className: 'btn btn-success btn-sm pull-right event',
        'aria-labelledby': 'event__heading-'+props.data.id

//        onKeyDown: (e) => { if (e.keyCode == 13) setExpanded((prevExpanded)=> !prevExpanded) },
    })

    const detailsButtonText = () => {
        if(isExpanded) return 'Hide Details'
        return 'Show Details'
    }

    return (<li aria-labelledby={'event__heading-'+props.data.id} className={'events__item'}>
        <div {...eventProps}  >
            <div className="row event__button">
                <div className="p-0 col-md-3 event__image">
                    <img className="img-fluid d-sm-none d-md-block hidden-xs hidden-sm event__image-sm" src={props.data.imageUrl + '?preset=sm-w'} alt=""/>
                    <img className="img-fluid d-none d-sm-block d-md-none hidden-md hidden-lg event__image-md" src={props.data.imageUrl + '?preset=med-w'} alt=""/>
                </div>
                <div className="col-md p-3">
                    <div className="event__content">
                        <h4 className="event__title" id={'event__heading-'+props.data.id}>
                            {props.data.name}
                        </h4>
                        <div className="event__datetime">
                            <EventTimeFrame startsOn={props.data.startsOn} endsOn={props.data.endsOn} />
                            <EventOnline addressName={props.data.address.name} />
                        </div>
                        <button {...toggleProps} >
                            { detailsButtonText() }
                        </button>
                    </div>
                </div>
            </div>
            <div {...getCollapseProps()} className="row event__detail" >
                <div className={'col m-3 event__detail__row'}>
                    <EventDetail {...props.data} />
                </div>
            </div>
        </div>



    </li>)
}

export default Event
