import React from 'react'

function GrizHubLink(props) {
    if(props.visibility === 'Public'){
        return (
            <a className={'btn btn-primary event__grizhubbtn btn-sm'} href={'https://umt.campuslabs.com/engage/event/' + props.id}>View on GrizHub</a>
        )
    }
    else{
        return ''
    }
}

export default GrizHubLink
