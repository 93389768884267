import React from 'react'
import ReactDOM from 'react-dom'
import Events from './events'
import './ponderosa.css'
import 'react-app-polyfill/ie11';


if (document.getElementById('events-feed')) {
    const propsContainer = document.getElementById("events-feed");
    const props = Object.assign({}, propsContainer.dataset);
    ReactDOM.render(<Events {...props} />,document.getElementById('events-feed'))
}
